import { Link } from "react-router-dom";

const Contact = () => {
    return (
        <>
         <section className="ser_v_araeea" id="contactus">
            <div className="container">
              <div className="contentss">
                <h2>Contact Us</h2>
                <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum sociis natoque penatibus et </p>
                <div className="maps_arar"><img src="images/contact_map.png" alt="" /></div>
              </div>
            </div>


            <div className="container reach_arar">
            <div className="contect_space">
              <div className="contentss">
                <h2>Reach out to us</h2>
                <div className="row">
                    <div className="col-lg-4">
                      <div className="contentss_bx">
                        <h3>E-mail Support</h3>
                        <p><Link to="mailto:support@worldtrust.com">support@worldtrust.com</Link></p>
                      </div>
                    </div>

                    <div className="col-lg-4">
                    <div className="contentss_bx"> 
                        <h3>Head Office</h3>
                        <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. </p>
                     </div>
                    </div>

                    <div className="col-lg-4">
                    <div className="contentss_bx">
                        <h3>Call Us Directy</h3>
                        <p><Link to="tel:+918700039024"> +91 8700039024</Link></p>
                        </div>
                    </div>
                </div>
              </div>
              </div>
            </div>



            <div className="container">
              <div className="contentss_forms">
                <div className="form_bx">
                  <h2>Let’s Get in Touch</h2>                  
                  <form action="">
                    <div className="row">
                     <div className="col-lg-6">
                      <div class="form-group">
                        <label>First Name</label>
                        <input type="text" class="form-control" name="" placeholder="Enter your first name" />
                      </div>
                     </div>

                     <div className="col-lg-6">
                      <div class="form-group">
                        <label>Last Name</label>
                        <input type="text" class="form-control" name="" placeholder="Enter your last name" />
                      </div>
                     </div>

                     <div className="col-lg-12">
                      <div class="form-group">
                        <label>E-mail ID <span className="red">*</span></label>
                        <input type="text" class="form-control" name="" placeholder="Enter your last name" />
                      </div>
                     </div>

                     <div className="col-lg-12">
                      <div class="form-group">
                        <label>Phone Number <span className="red">*</span></label>
                        <input type="text" class="form-control" name="" placeholder="Enter your last name" />
                      </div>
                     </div>

                     <div className="col-lg-12">
                      <div class="form-group">
                        <label>Message</label>
                        <textarea class="form-control" rows="7" cols="7"></textarea>
                      </div>
                     </div>
 
                     <div className="col-lg-12">
                      <div class="form-group mt-4">
                        <button type="submit" value="Send Message" class="sub_mitess">Send Message</button>
                      </div>
                     </div>

                    </div>
                    
                    </form>

                </div>
                <div className="usr_mg_arss"><img src="images/cont_sm.png" alt="" /></div>
              </div>
            </div>
         </section>

         
         

        </>
    )
};

export default Contact;