import { Link } from "react-router-dom";
import {React, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import CountUp from 'react-countup';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Modal, Button } from 'react-bootstrap'
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css';



const homeslider = {
    margin:0,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};



const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};




const Home = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const videoshow = () => setShow(true);
  
    return (
        <>
 


<Modal className="all_supportss" id="video_pop" show={show} onHide={handleClose}>
  <Modal.Header closeButton></Modal.Header>
  <Modal.Body><iframe width="100%" height="450" src="https://www.youtube.com/embed/O0_46dWQsts?si=1ousg9fC6gATpnz6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></Modal.Body>
</Modal>
	  


<div className="fulls_wd" id="ovr_cntentss">
 <OwlCarousel className="slider-items owl-carousel" id="home_sliserss" {...homeslider}>
                <div className="item">
				 <img src="images/banner_bg.png" alt="" />
    <div className="container">
        <div className="hdr_cnt_aarea">
            <div className="bg_contentss">
                <h2>Uniting hearts for a better tomorrow</h2>
                <p>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus
                </p>
                <Link to='/' className="al_bnt_supt">Start Now</Link>
            </div>
            <div className="pustents_conts">
                <div className="ur_tx_area">
                    <p><span>Urgent Cause</span></p>
                    <h5>Help people from sea side have a safe and secure Life</h5>
					
					<div className="skills">
                            <ProgressBar completed={88} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
					
                    <div className="pricess">₹36,000 Raised</div>
                    <h6>
                        20.12 Donations <Link to='/'><img src="images/arrow_surcals.png" alt="" /></Link>
                    </h6>
                </div>
            </div>
        </div>
    </div>
				</div>
                <div className="item">
								 <img src="images/banner_bg.png" alt="" />
    <div className="container">
        <div className="hdr_cnt_aarea">
            <div className="bg_contentss">
                <h2>Uniting hearts for a better tomorrow</h2>
                <p>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus
                </p>
                <Link to='/' className="al_bnt_supt">Start Now</Link>
            </div>
            <div className="pustents_conts">
                <div className="ur_tx_area">
                    <p><span>Urgent Cause</span></p>
                    <h5>Help people from sea side have a safe and secure Life</h5>
					
					<div className="skills">
                            <div className="skill">
                                <div className="skill-bar">
                                    <div className="skill-percentage green_bg" per="90%" style={{"max-width" : "90%;"}}></div>
                                </div>
                            </div>
                        </div>
					
                    <div className="pricess">₹36,000 Raised</div>
                    <h6>
                        20.12 Donations <Link to='/'><img src="images/arrow_surcals.png" alt="" /></Link>
                    </h6>
                </div>
            </div>
        </div>
    </div>
				</div>
 </OwlCarousel>				


   
</div>

	  
<section className="ser_v_araeea about_us" id="AboutUs">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="img_bx_ar_ard">
                    <img src="images/abouts.png" alt="" />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="rdy_cont_area">
                    <div className="middlss">
                        <h4>About Worldtrust</h4>
                        <h6>A path to purposeful impact on society</h6>
                        <p>
                            WorldTrust.io is a pioneering platform designed to unify charitable efforts through an innovative blend of transparency and collaboration. It provides an intuitive space where NGOs and donors converge, offering a
                            streamlined experience that eliminates inefficiencies and fosters trust among its users.
                        </p>

                        <p>
                            Utilizing blockchain technology, WorldTrust.io ensures that every donation is traceable and every charitable cause is verified, empowering users to contribute to change with confidence. Serving as a catalyst for
                            global cooperation, the platform supports diverse causes, from educational initiatives in underserved areas to environmental conservation projects around the globe.
                        </p>

                        <p>By aggregating these efforts, WorldTrust.io amplifies each contributor's impact and facilitates sustained change across communities.</p>

                        <Link to='/about-us' className="vw_details">Know More <img src="images/rd_tk_r_arrow.png" alt="" /></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section className="ser_v_araeea pt-4 animate__animated animate__moveUp" id="contters">
    <div className="container">
        <div className="brd_top_btm">
            <div className="row">
                <div className="col-lg-3 col-6">
                    <div className="counts_list">
                        <div className="contter"><h3 className="counter"><CountUp end={12000} /></h3><span className="cnt_k">k+</span></div>
                        <p>Total Donations Raised</p>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="counts_list">
                        <div className="contter"><h3 className="counter"><CountUp end={9000} /></h3><span className="cnt_k">k+</span></div>
                        <p>Total Beneficiaries help</p>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="counts_list">
                        <div className="contter"><h3 className="counter"><CountUp end={2000} /></h3><span className="cnt_k">k+</span></div>
                        <p>Total hours of volunteers clocked</p>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="counts_list">
                        <div className="contter"><h3 className="counter"><CountUp end={1000} /></h3><span className="cnt_k">k+</span></div>
                        <p>Total amount of merits points given out</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="ser_v_araeea pt-0" id="Achievers">
    <div className="container">
        <div className="al_sec_ctxt text-center">
            <div className="marg_cnteers">
                <div className="cntstts">
                    <span className="awrd"><img src="images/gold_aword.png" alt="" /></span>
                    <h3>Achievers</h3>
                    <span className="awrd"><img src="images/gold_aword.png" alt="" /></span>
                </div>
            </div>
        </div>

        <div className="tablt_dat_listst">
            <div className="al_data_lists">
                <div className="tabs_menuss wrapper">	


    


  
				
                    <div className="ctx_contnt">Leaderboard</div>
					<Tabs>
                    <div className="ctx_tabsss">
                        <div className="tab-wrapper">
						  <div className="tabs_list">
						   <TabList>
							  <Tab>Donor</Tab>
							  <Tab>Volunteer</Tab>
							  <Tab>Company</Tab>
							</TabList>
							<span className="share_links"><img src="images/share-android.png" alt="" /></span>
							</div>
                        </div>
                    </div>
					<div className="content-wrapper">
					<TabPanel>					
					  <div className="table-responsive">
                                <table className="table table-striped borddr_none">
                                    <tbody>
                                        <tr>
                                            <td>Rank</td>
                                            <td>Volunteer</td>
                                            <td>Month</td>
                                            <td>Amount</td>
                                            <td>Merits</td>
                                        </tr>

                                        <tr>
                                            <td><img src="images/Medals.png" alt="" /></td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td><img src="images/Silver.png" alt="" /></td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy2.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td><img src="images/Silver.png" alt="" /></td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy2.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy2.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
					</TabPanel>
					<TabPanel>
					  <div className="table-responsive">
                                <table className="table table-striped borddr_none">
                                    <tbody>
                                        <tr>
                                            <td>Rank</td>
                                            <td>Volunteer</td>
                                            <td>Month</td>
                                            <td>Amount</td>
                                            <td>Merits</td>
                                        </tr>

                                        <tr>
                                            <td><img src="images/Medals.png" alt="" /></td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td><img src="images/Silver.png" alt="" /></td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy2.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td><img src="images/Silver.png" alt="" /></td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy2.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy2.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
					</TabPanel>
					
					
					<TabPanel>
					  <div className="table-responsive">
                                <table className="table table-striped borddr_none">
                                    <tbody>
                                        <tr>
                                            <td>Rank</td>
                                            <td>Volunteer</td>
                                            <td>Month</td>
                                            <td>Amount</td>
                                            <td>Merits</td>
                                        </tr>

                                        <tr>
                                            <td><img src="images/Medals.png" alt="" /></td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td><img src="images/Silver.png" alt="" /></td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy2.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td><img src="images/Silver.png" alt="" /></td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy1.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                                <div className="usr_namess"><img src="images/user_dummy2.png" alt="" /></div>
                                                <span className="namess">Messiah Watkins</span>
                                            </td>
                                            <td>August</td>
                                            <td>200.00 USD</td>
                                            <td>50</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
					</TabPanel>
					
					</div>
					</Tabs>

                   
                
                </div>
            </div>
            <img src="images/tabless.png" className="mb_view_hide" alt="" />
        </div>
    </div>
</section>

<section className="ser_v_araeea pt-0" id="charity_campaign">
    <div className="container">
        <div className="al_sec_ctxt">
            <h2>Charity Campaign</h2>
        </div>

        <div className="row">
            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                       <div className="skills">
                            <ProgressBar completed={88} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg1.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={70} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg2.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={90} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>

            <div className="col-lg-12 mt-5">
                <div className="vei_als">
                    <Link to="">
                        <span className="managess_bnt">All Campaigns <img src="images/chevron-right.png" alt="" /></span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="ser_v_araeea pt-0" id="events_hm">
    <div className="container">
        <div className="al_sec_ctxt">
            <h2>Events</h2>
        </div>

        <div className="row">
            <div className="col-lg-8">
                <div className="evt_bgr">
                    <div className="dats_time">03 <span>May</span></div>
                    <img src="images/events_bg.png" alt="" />
                </div>
            </div>

            <div className="col-lg-4">
                <div className="evntts_cnt">
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <span>Fri , May 03 2024</span>
                    <p>
                        Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum
                        obcaecati voluptas est aliquam soluta. Aut quia minusLorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum d
                    </p>

                    <Link className="btn_read">Register Now <img src="images/ar_right_icon.png" alt="" /></Link>
                </div>
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-lg-3">
               <Link to="/events">
                <div className="evt_bgr_sm">
                    <div className="mg_bxxs"><img src="images/events_sm1.png" alt="" /></div>
                    <span>Fri , Jan 26 2014</span>
                    <p>Lorem ipsum dolor sit amet. Sedhan consequatur necessitatibu</p>
                </div>
                </Link>
            </div>

            <div className="col-lg-3">
            <Link to="/events">
                <div className="evt_bgr_sm">
                    <div className="mg_bxxs"><img src="images/events_sm2.png" alt="" /></div>
                    <span>Fri , Jan 26 2014</span>
                    <p>Lorem ipsum dolor sit amet. Sedhan consequatur necessitatibu</p>
                </div>
                </Link>
            </div>

            <div className="col-lg-3">
            <Link to="/events">
                <div className="evt_bgr_sm">
                    <div className="mg_bxxs"><img src="images/events_sm3.png" alt="" /></div>
                    <span>Fri , Jan 26 2014</span>
                    <p>Lorem ipsum dolor sit amet. Sedhan consequatur necessitatibu</p>
                </div>
                </Link>
            </div>

            <div className="col-lg-3">
            <Link to="/events">
                <div className="evt_bgr_sm">
                    <div className="mg_bxxs"><img src="images/events_sm4.png" alt="" /></div>
                    <span>Fri , Jan 26 2014</span>
                    <p>Lorem ipsum dolor sit amet. Sedhan consequatur necessitatibu</p>
                </div>
                </Link>
            </div>

            <div className="col-lg-12 mt-5">
                <div className="vei_als">
                    <Link to="/events">
                        <span className="managess_bnt">Browse All Events <img src="images/chevron-right.png" alt="" /></span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="ser_v_araeea pt-0" id="part_hm">
    <div className="container">
        <div className="al_sec_ctxt text-center">
            <h2>Partner</h2>
        </div>
        <div className="row">
            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo1.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo2.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo3.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo4.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo4.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo5.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo6.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo7.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo8.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo8.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo9.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo10.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo11.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo12.png" alt="" />
                </div>
            </div>

            <div className="col-lg-2">
                <div className="part_boxxx">
                    <img src="images/part_lgo12.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>

<section className="ser_v_araeea pt-4" id="when_people">
    <div className="container">
        <div className="row">
            <div className="col-lg-7">
                <div className="when_pep_hlp">
                    <h3>When people help people change happens</h3>
                    <p>
                        Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati
                        voluptas est aliquam soluta.
                    </p>
                    <Link to="" className="btn_read">Register Now <img src="images/ar_right_icon.png" alt="" /></Link>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="when_mg_bx">
                    <img src="images/when_arar.png" alt="" />
                    <div className="retings_boxx">
                        <h3>210 Campaign <span>Running Listed </span></h3>

                        <div className="skills">
                            <ProgressBar completed={90} bgColor={'#D05959'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>

                        <span className="cam_runnings">195 campaigns running</span>
                        <p>
                            20.12 Donations <Link to=""><img src="images/arrow_surcals.png" alt="" /></Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="ser_v_araeea pt-4" id="why_choose_hm">
    <div className="container">
        <div className="al_sec_ctxt">
            <h2>Why choose worldtrust</h2>
        </div>

        <div className="row">
            <div className="col-lg-7">
                <div className="why_boxxx">
                    <img src="images/why_choose.png" alt="" />
                </div>
            </div>
            <div className="col-lg-5">
                <div className="why_ch_text">
                    <ul>
                        <li>
                            <h3><img src="images/tik_right.png" alt="" /> Lorem ipsum dolor</h3>
                            <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima</p>
                        </li>
                        <li>
                            <h3><img src="images/tik_right.png" alt="" /> Lorem ipsum dolor</h3>
                            <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima</p>
                        </li>
                        <li>
                            <h3><img src="images/tik_right.png" alt="" /> Lorem ipsum dolor</h3>
                            <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima</p>
                        </li>
                        <li>
                            <h3><img src="images/tik_right.png" alt="" /> Lorem ipsum dolor</h3>
                            <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="ser_v_araeea" id="Worldtrust_hm">
    <div className="container">
        <div className="worlds_box">
            <div className="al_sec_ctxt">
                <h2>Worldtrust makes happy customers</h2>
            </div>
			
			<div>
                <OwlCarousel className="slider-items owl-carousel" id="three_points" {...options}>
                <div className="item">
                    <div className="hd_ara_cntxt">
                        <span className="mg_aryya">Customer Stories</span>
                        <div className="contents_arar">
                            <p>
                                Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati
                                voluptas est aliquam soluta.
                            </p>
                            <h3>-Willian John</h3>
                        </div>
                    </div>
                </div>

                <div className="item">
                    <div className="hd_ara_cntxt imgss">
                        <span className="mg_aryya">Customer Stories</span>
                        <div className="usrre_mgss">
                            <img src="images/user_sld.png" className="user_mgss" alt="" />
                            <div className="play_tikss">
							 <Button variant="primary" onClick={videoshow}>
                                <img
                                    src="images/play.png"
                                    alt=""
                                    className="vdo_ply video-btn"  
                                />
								      </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="item">
                    <div className="hd_ara_cntxt">
                        <span className="mg_aryya">Customer Stories</span>
                        <div className="contents_arar">
                            <p>
                                Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati
                                voluptas est aliquam soluta.
                            </p>
                            <h3>-Willian John</h3>
                        </div>
                    </div>
                </div>

                <div className="item">
                    <div className="hd_ara_cntxt">
                        <span className="mg_aryya">Customer Stories</span>

                        <div className="contents_arar">
                            <p>
                                Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati
                                voluptas est aliquam soluta.
                            </p>
                            <h3>-Willian John</h3>
                        </div>
                    </div>
                </div>
                </OwlCarousel>
            </div>

        </div>
    </div>
</section>

<section className="ser_v_araeea pt-4" id="news_featured_hm">
    <div className="container">
        <div className="al_sec_ctxt">
            <h2>News & Featured stories</h2>
        </div>

        <div className="row">
            <div className="col-lg-4">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="images/news_bg_sm.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut</p>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="images/news_bg_sm1.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut</p>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="news_lists_parts">
                    <div className="un_lissts">
                        <div className="mg_arara"><img src="images/news_sm_mg1.png" alt="" /></div>
                        <div className="contenner">
                            <span>08-09-2023 <span className="ctgrees">Category</span></span>
                            <p>Lorem ipsum dolor sit amet. Sed consetur necessitatibus</p>
                        </div>
                    </div>

                    <div className="un_lissts">
                        <div className="mg_arara"><img src="images/news_sm_mg2.png" alt="" /></div>
                        <div className="contenner">
                            <span>08-09-2023 <span className="ctgrees">Category</span></span>
                            <p>Lorem ipsum dolor sit amet. Sed consetur necessitatibus</p>
                        </div>
                    </div>

                    <div className="un_lissts">
                        <div className="mg_arara"><img src="images/news_sm_mg3.png" alt="" /></div>
                        <div className="contenner">
                            <span>08-09-2023 <span className="ctgrees">Category</span></span>
                            <p>Lorem ipsum dolor sit amet. Sed consetur necessitatibus</p>
                        </div>
                    </div>

                    <div className="un_lissts">
                        <div className="mg_arara"><img src="images/news_sm_mg4.png" alt="" /></div>
                        <div className="contenner">
                            <span>08-09-2023 <span className="ctgrees">Category</span></span>
                            <p>Lorem ipsum dolor sit amet. Sed consetur necessitatibus</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-12 mt-5">
                <div className="vei_als">
                    <Link to="">
                        <span className="managess_bnt">See More <img src="images/chevron-right.png" alt="" /></span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="ser_v_araeea pt-4" id="news_featured_hm">
    <div className="container">
        <div className="al_sec_ctxt">
            <h2>Frequently Asked Questions</h2>
        </div>
		


        <div className="accordion_container">	
		    <Accordion>
			  <AccordionItem header="Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut" >
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
			  </AccordionItem>

			  <AccordionItem header="Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
			  </AccordionItem>

			  <AccordionItem header="Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
			  </AccordionItem>

			  <AccordionItem header="Lorem ipsum dolor sit amet. Sitatibus aut natus minima eum">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
			  </AccordionItem>
			</Accordion>
		
		
            
        </div>

        <div className="mt-5">
            <div className="vei_als">
                <Link to="">
                    <span className="managess_bnt">All FAQs <img src="images/chevron-right.png" alt="" /></span>
                </Link>
            </div>
        </div>
    </div>
</section>
        </>
    )
};

export default Home;