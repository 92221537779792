import { Link } from "react-router-dom";
import {React, useState} from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const Donate = () => {
    return (
        <>


<div className="all_suport_bg">
<section className="ser_v_araeea" id="do_telss_page">
<div className="container">
   <div className="al_sec_ctxt do_detailss">
     <h2>Every Heartbeat Matters: Saving Aiden's Heart <span className="blocks">[Heart Menders]</span></h2>
     <p>S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></p>
   </div>  

   <div className="row">
    <div className="col-lg-8">
     <div className="us_bg_imgas">
        <div className="ovr_logss"><img src="images/gr_logo.png" alt="" /></div>
        <img src="images/donate_dtls.png" alt="" />
     </div>

     <div id="donates_tabs">
      <Tabs>
        <div className="tabs_list">
            <TabList>
                <Tab>Story</Tab>
                <Tab>Updates <span>(05)</span></Tab>
                <Tab>Donations <span>(320)</span></Tab>
                <Tab>Testimonials <span>(20)</span></Tab>
            </TabList>
        </div>

        <div className="content-wrapper">
            <TabPanel>
                <div className="tab_data_adss">
                 <div className="und_data_listst">
                  <h4>Every Heartbeat Matters: Saving Aiden's Heart</h4>
                  <p>In the heart of Tayabas City, Quezon Province, where the gentle hum of everyday life blends with the vibrant spirit of our close-knit community, our family faces a challenge that has tested the bounds of our strength and unity. I am Rose Marie M. Antonio, a mother, a wife, and today, a bearer of a story that is both a testament to love and a call to kindness.</p>
                  <p>Our youngest, Aiden Railey Antonio, is a beacon of joy in our lives. With eyes that sparkle with innocence and a smile that lights up our dimmest days, Aiden is everything to us. His sister, Janemery Shane M., adores him beyond words, and together, they create a world filled with laughter and dreams. My husband, Ariel T. Antonio, works tirelessly as a helper, embodying the resilience and dedication that have become the cornerstones of our family. Yet, despite his unwavering efforts, the reality we face is one that his salary alone cannot conquer.</p>
                  
                  <div className="dtm_mgss"><img src="images/donate_dt_sm1.png" alt="" /></div>

                  <p>At just six months old, our world was shaken to its core when Aiden was diagnosed with Congenital Heart Disease, specifically a VENTRICULAR SEPTAL DEFECT. This condition, a gap in the heart that disrupts the normal flow of blood, requires a solution that lies beyond our reach—an Open Heart Surgery. Now, at 2 years and 7 months old, the need for this surgery has become urgent, a race against time to mend a heart that beats with so much life, yet struggles with each passing day.</p>
                  <p>Our journey has been a tumultuous one, marked by sleepless nights filled with prayers and days overshadowed by the weight of uncertainty. The thought of losing our son to a condition that is treatable, yet financially unattainable, is a reality that we cannot bear. Thus, with a heart heavy with both hope and despair, I turn to you, our community, our extended family, and anyone whose path this plea may cr</p>
                  
                  <div className="dtm_mgss"><img src="images/donate_dt_sm2.png" alt="" /></div>

                  <p>We are seeking your support to save Aiden's heart. Your generosity can light the way to a future where Aiden can play, laugh, and grow alongside his sister, where he can chase his dreams without the shadow of illness looming over him. Every contribution, no matter the size, brings us closer to giving Aiden the chance at life he so deserves.</p>
                  <p>As a mother, my only wish is for my children to live a life filled with joy, health, and possibilities. For Aiden, this wish is within reach, but only with your help. Together, we can bridge the gap between despair and hope, between illness and health. Let us unite in the spirit of compassion and kindness to give Aiden a fighting chance.</p>
                 
                  <div className="dtm_mgss"><img src="images/donate_dt_sm3.png" alt="" /></div>

                  <p>To those who have taken a moment to embrace our story, to feel the pulse of a mother's plea, I extend my deepest gratitude. Your support means the world to us, a beacon of hope in our journey towards healing Aiden's heart. Thank you for considering our call for help, for in your kindness, lies the key to Aiden's brighter tomorrow.</p>
                  <p>All funds raised will be managed by Give.asia and pay to the hospital directly for Aiden's surgery!</p>
                 </div>                    
                </div>
            </TabPanel>
            <TabPanel>
                ggfhf
            </TabPanel>

            <TabPanel>
                fghgfhfg
            </TabPanel>

            <TabPanel>
                fghgfhfg dgdf
            </TabPanel>
        </div>
    </Tabs>
    </div>

    </div> 
    <div className="col-lg-4">
     <div className="rt_donat_lists">
      <div className="plac_donates">
       <h3>Raised 10,00,000 USD  </h3> 
       <p>of  60,00,000 USD</p> 
       <div className="skills">
         <ProgressBar completed={90} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
       </div>
       <div className="do_btnss">
        <Link to="" className="org_grad">Please Donate</Link>
        <Link to="" className="nrmllss">Share This Campaign</Link>        
       </div>
      </div> 

      <div className="popls_listst">
       <h3><img src="images/gr_tik_sm.png" alt="" /> 300+People just donated</h3>
       <div className="mn_puplss">
       <div className="p_lists">
        <ul>
            <li>
              <span className="usr_boxx"><img src="images/b_sm_logo.png" alt="" /></span>  
            </li>
            <li>
              <span>Saelee Mealea</span>
              <h4>300 USD</h4>  
            </li>
            <li><span className="top_doss">Top Donation</span></li>
        </ul>
       </div>

       <div className="p_lists">
        <ul>
            <li>
              <span className="usr_boxx"><img src="images/b_sm_logo.png" alt="" /></span>  
            </li>
            <li>
              <span>Saelee Mealea</span>
              <h4>300 USD</h4>  
            </li>
            <li><span className="top_doss">Top Donation</span></li>
        </ul>
       </div>

       <div className="p_lists">
        <ul>
            <li>
              <span className="usr_boxx"><img src="images/b_sm_logo.png" alt="" /></span>  
            </li>
            <li>
              <span>Saelee Mealea</span>
              <h4>300 USD</h4>  
            </li>
            <li><span className="top_doss">Top Donation</span></li>
        </ul>
       </div>

       <div className="p_lists">
        <ul>
            <li>
              <span className="usr_boxx"><img src="images/b_sm_logo.png" alt="" /></span>  
            </li>
            <li>
              <span>Saelee Mealea</span>
              <h4>300 USD</h4>  
            </li>
            <li><span className="top_doss">Top Donation</span></li>
        </ul>
       </div>

       <div className="p_lists">
        <ul>
            <li>
              <span className="usr_boxx"><img src="images/b_sm_logo.png" alt="" /></span>  
            </li>
            <li>
              <span>Saelee Mealea</span>
              <h4>300 USD</h4>  
            </li>
            <li><span className="top_doss">Top Donation</span></li>
        </ul>
       </div>

       <div className="user_pop_filds">
       <div className="p_lists">
        <ul>
            <li>
              <span className="usr_boxx">S</span>  
            </li>
            <li>              
              <h4>Saephan Bourey</h4>  
              <span>Family Member</span>
            </li>
            <li>&nbsp;</li>
        </ul>
       </div>

       <div className="p_lists">
        <ul>
            <li>
              <span className="usr_boxx">M</span>  
            </li>
            <li>              
              <h4>Meng Bourey</h4>  
              <span>Organizer</span>
            </li>
            <li><Link to=""><span className="top_doss">Top Donation</span></Link></li>
        </ul>
       </div>
       </div>

       </div>
      </div>


     </div> 

     
     <div className="trust_safety">
        <h2>Trust & Safety</h2>
            <div className="und_lis_prt">
                <span className="ic_mgss_ar"><img src="images/protection.png" alt="" /></span>
                <p>Your donation is protected for 12 months by our Giving Guarantee</p>
            </div>

            <div className="und_lis_prt">
                <span className="ic_mgss_ar"><img src="images/ambulance.png" alt="" /></span>
                <p>All donations go directly to the hospital or nonprofit organisation</p>
            </div>

            <div className="und_lis_prt">
                <span className="ic_mgss_ar"><img src="images/invention.png" alt="" /></span>
                <p>Your credit card information is encrypted and not stored in our system</p>
            </div>
      </div>

      <div className="rt_donat_lists mt-4">
        <h3><img src="images/gr_tik_sm.png" alt="" /> Verified by give Healthcare</h3>
        <p>Our verification process involves contacting and meeting the fundraiser as well as the beneficiary, reviewing financial, medical, and other applicable documents that offer evidence of the need. </p>
        <Link to="" className="clr_tx">LEARN MORE</Link>
      </div>

      <div className="rt_donat_lists mt-4">
        <h3><img src="images/hand_tik.png" alt="" /> This Campaign uses Boosting</h3>
        <p>Our verification process involves contacting and meeting the fundraiser as well as the beneficiary, reviewing financial, medical, and other applicable documents that offer evidence of the need. </p>
        <Link to="" className="clr_tx">LEARN MORE</Link>
      </div>

      <div className="btn_manages mt-3">
        <Link to="" className="nrmllss">Contact Support <img src="images/chevron-down.png" alt="" /> </Link>        
       </div>

    </div> 
   </div>  
</div>
</section>

    <section className="ser_v_araeea" id="charity_campaign">
     <div className="container">
        <div className="al_sec_ctxt">
            <h2>Similar Campaign  <span className="user_lists"><Link to='/donate'>See more</Link></span></h2>
        </div>
        <div className="row">
            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                       <div className="skills">
                            <ProgressBar completed={88} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg1.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={70} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg2.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={90} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
    </div>




        </>
    )
};

export default Donate;