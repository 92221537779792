import { Link } from "react-router-dom";

const Careers = () => {
    return (
        <>

        <section className="ser_v_araeea green_bg manages_heis" id="about_pgs">
            <img src="images/flo_partss.png" className="flo_partss" alt="" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="al_sprts_pages">
                            <h3>Careers</h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="cnt_datas">
                            Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="ser_v_araeea" id="page_contents">
            <div className="container">
                <div className="discriptionss mt-0">
                    <div className="un_cntxtx">
                        <h3>Operations Manager ( Permanent )</h3>
                        <span>Phnom Penh , Combodia</span>
                        <p>
                            Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum socCum
                            sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum socCum sociis
                            natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum soc
                        </p>
                    </div>

                    <div className="un_cntxtx">
                        <h4>Qualifications</h4>
                        <p>
                            Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum socCum
                            sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum socCum sociis
                            natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum soc
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section className="ser_v_araeea evnetsts pt-2">
            <div className="container">
                <div className="al_sec_ctxt text-center">
                    <h2>Fill this form to move ahead !</h2>
                </div>

                <div className="form_boxx">
                  <div className="form_arar mt-0">  
                  <div className="mang_und_listst mt-0">  
                    <form action="">
                        <div className="row">
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>First Name</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter first name" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter last name" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter email address" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Phone</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter phone number" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Gender</label>
                                    <select className="form-control">
                                        <option value="">Choose your gender</option>
                                    </select>
                                </div>
                            </div>


                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Location</label>
                                    <select className="form-control">
                                        <option value="">Choose your location</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div class="form-group" id="browsers">
                                    <label>Resume</label>
                                    <input type="file" class="form-control" name="" placeholder="Attach your resume" />
                                    <span className="smtxtx">Attach your resume or paste your drive link here</span>
                                </div>
                            </div>

                            <div className="col-lg-12">
                              <div class="form-group mt-3">
                                <button type="submit" value="Apply" class="sub_mitess">Apply</button>
                              </div>
                            </div>

                        </div>
                    </form>
                  </div> 
                  </div> 
                </div>
            </div>
        </section>

    </>
    )
};

export default Careers;