import { Link } from "react-router-dom";
import {React, useState} from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const Donate = () => {
    return (
        <>

        <div className="all_suport_bg">
            <section className="ser_v_araeea" id="pay_alss">
                <div className="container">
                    <div className="cntr_box_all">
                      

                      <div className="form_arar sussess">                      
                        <div class="sus_lists">
                          <img src="images/suss_tik.png" alt="" />
                          <h2>Donation Successful</h2>
                          <p>Thank you for donating with Worldtrust. Please check your transaction details below</p>
                        </div>

                        <div className="brd_liness mt-4 mb-4">&nbsp;</div>

                        <div className="top_user">
                         <div className="usr_pickss"><img src="images/donate_dt_sm1.png" alt="" /></div>  
                         <div className="cnt_tx_cntss">
                         Every Heartbeat Matters: Saving Aiden's Heart [Heart Menders]    
                         </div>
                        </div>  

                        <div className="brd_liness mt-4 mb-4">&nbsp;</div> 

                        <div class="form-group manags_cntxt black_tx">Transactions Details</div>
                        <div class="form-group manags_cntxt">Transaction ID <span class="pricde">UTG867IUT8855677</span></div>

                        <div className="brd_liness mt-2 mb-4">&nbsp;</div>

                        <div className="pri_liststs">
                          <div class="form-group manags_cntxt">Bank Name <span class="pricde black_tx">City Bank</span></div>
                          <div class="form-group manags_cntxt">Account No. <span class="pricde black_tx">8675876847548432</span></div>
                          <div class="form-group manags_cntxt">IFSC Code <span class="pricde black_tx">IUT89HKJ5</span></div>
                          <div class="form-group manags_cntxt">Amount <span class="pricde black_tx">200.00 USD</span></div>
                        </div>

                        <div className="brd_liness mt-2 mb-4">&nbsp;</div>

                        

                        <div className="gpr_g_textss">
                           <img src="images/g_flor.png" alt="" />
                           <div className="grp_txtx">
                           <h6><span className="b_text">20</span> <span className="sm_text">Merit Points</span> Credited</h6>
                           </div>
                          </div> 

                        <div className="price_list link_btnss">
                          <Link to="" className="act">Go to Dashboard</Link>                        
                        </div>

                        <div className="mang_und_listst">     
                          <div className="form-group">
                           <p className="by_textxt">
                           You will receive a confirmation email once we have verified the donation details. If you have any enquiries please email customer support at
                             <Link to="mailto:support@worldtrust.io">support@worldtrust.io</Link> 
                           </p>
                          </div>  

                               
                        </div>
                      </div>                     
                    </div>
                </div>
            </section>
        </div>

        </>
    )
};

export default Donate;