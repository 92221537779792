import { Link } from "react-router-dom";
import {React, useState} from 'react';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';





const FAQs = () => {
    return (
        <>

<section className="ser_v_araeea green_bg manages_heis" id="about_pgs">
		<img src="images/flo_partss.png" className="flo_partss" alt="" />
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<div className="al_sprts_pages">
						<h3>FAQ’s</h3>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="cnt_datas">
                     Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati 
                     voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. 
                     Sed consequatur necessitatibus aut natus minima eum  
					</div>
				</div>
			</div>
		</div>
	</section>


    <section className="ser_v_araeea" id="faqss">
        <div className="container">
            <div className="row">
                <div className="col-lg-3"><div className="faq_heddigs">General FAQ’s</div></div>
                <div className="col-lg-9">
                 <div className="accordion_container">	
                    <Accordion>
                    <AccordionItem header="Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut" >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
                    </AccordionItem>

                    <AccordionItem header="Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
                    </AccordionItem>

                    <AccordionItem header="Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
                    </AccordionItem>

                    <AccordionItem header="Lorem ipsum dolor sit amet. Sitatibus aut natus minima eum">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
                    </AccordionItem>
                    </Accordion>
                 </div>                    
                </div>
            </div>


            <div className="row">
                <div className="col-lg-3"><div className="faq_heddigs">volunteers FAQ’s</div></div>
                <div className="col-lg-9">
                 <div className="accordion_container">	
                    <Accordion>
                    <AccordionItem header="Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut" >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
                    </AccordionItem>

                    <AccordionItem header="Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
                    </AccordionItem>

                    <AccordionItem header="Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
                    </AccordionItem>

                    <AccordionItem header="Lorem ipsum dolor sit amet. Sitatibus aut natus minima eum">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrorem ipsum.
                    </AccordionItem>
                    </Accordion>
                 </div>                    
                </div>
            </div>
        
          
        </div>
    </section>

    <section className="ser_v_araeea pt-0">
        <div className="container">
            <div className="faq_bgrr">
                <img src="images/faq_bgrr.png" className="bg_faqq" alt="" />
                <div className="over_cntss">
                   <div className="medialss"> 
                    <h2>Still Need Help?</h2>
                    <p>We’re here to support</p>
                    <span>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum </span>
                    <div className="link_cntnts"><Link to="">Contact Us <img src="images/ar_right_icon.png" alt=""></img></Link></div>
                    </div> 
                </div>
            </div>
        </div>
    </section>

        </>
    )
};

export default FAQs;