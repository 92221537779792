import { Link } from "react-router-dom";
import {React, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import CountUp from 'react-countup';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const evntssslider = {
    margin:0,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};


const Login = () => {
    return (
        <>
	
	<section className="ser_v_araeea green_bg eventss_pgs" id="about_pgs">
		<img src="images/flo_partss.png" className="flo_partss" alt="" />
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<div className="al_sprts_pages">
						<h3>Events</h3>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="cnt_datas">
					Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum 
					</div>
				</div>
			</div>
		</div>
	</section>

	<section className="ser_v_araeea abt_mgeess_bx evnt_slisder allss">
		<div className="container">
		<OwlCarousel className="slider-items owl-carousel" id="home_sliserss" {...evntssslider}>
		  <div className="item">
			<div className="sld_partsss">
			 <img src="images/evnts_sliddr.png" alt="" />
			 <div className="over_cntsst">
               <div className="datss"><img src="images/uil_calender.png" alt="" /> 20 May 2024</div>
               <h2>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h2>
			   <p><img src="images/ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</p>
			 </div>
			</div>	
		  </div>

		  <div className="item">
			<div className="sld_partsss">
			 <img src="images/evnts_sliddr.png" alt="" />
			 <div className="over_cntsst">
               <div className="datss"><img src="images/uil_calender.png" alt="" /> 20 May 2024</div>
               <h2>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h2>
			   <p><img src="images/ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</p>
			 </div>
			</div>	
		  </div>
		</OwlCarousel>
		</div>
	</section>

	<section className="ser_v_araeea pt-0 evnetsts">
		<div className="container">
          <div className="entnts_tabss">
		  <Tabs>
			<div className="tabs_list">
				<TabList>
					<Tab>Upcoming Events</Tab>
					<Tab>Past Events</Tab>
				</TabList>
				<div className="fillerss"><img src="images/mage_filter.png" alt="" /></div>
			</div>

			<div className="content-wrapper">
				<TabPanel>
					<div className="main_tabs_data">
                      <div className="un_data_list">
					    <Link to="/events-details">
						<div className="usr_mgss"><img src="images/events_sm.png" alt="" /></div>
						</Link>
						<div className="cntent_tx">
						  <div className="dat_time">
							<span className="datess"><img src="images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <Link to="/events-details"><h2>Lorem ipsum dolor : sit amet  Sed consequatur  necessitatibus</h2></Link>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr"><Link to="" className="red_mr">Register Now <img src="images/ar_right_icon.png" alt="" /></Link></div>
						  </div>
						</div>
					  </div>


					  <div className="un_data_list">
					  <Link to="/events-details"><div className="usr_mgss"><img src="images/who_abt.png" alt="" /></div></Link>
						<div className="cntent_tx">
						  <div className="dat_time">
							<span className="datess"><img src="images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <Link to="/events-details"><h2>Lorem ipsum dolor : sit amet  Sed consequatur  necessitatibus</h2></Link>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr"><Link to="" className="red_mr">Register Now <img src="images/ar_right_icon.png" alt="" /></Link></div>
						  </div>
						</div>
					  </div>


					  <div className="un_data_list">
					   <Link to="/events-details"><div className="usr_mgss"><img src="images/why_choose.png" alt="" /></div></Link>
						<div className="cntent_tx">
						  <div className="dat_time">
							<span className="datess"><img src="images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <Link to="/events-details"><h2>Lorem ipsum dolor : sit amet  Sed consequatur  necessitatibus</h2></Link>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr"><Link to="" className="red_mr">Register Now <img src="images/ar_right_icon.png" alt="" /></Link></div>
						  </div>
						</div>
					  </div>


					  <div className="un_data_list">
					    <Link to="/events-details"><div className="usr_mgss"><img src="images/events_sm4.png" alt="" /></div></Link>
						<div className="cntent_tx">
						  <div className="dat_time">
							<span className="datess"><img src="images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <Link to="/events-details"><h2>Lorem ipsum dolor : sit amet  Sed consequatur  necessitatibus</h2></Link>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr"><Link to="" className="red_mr">Register Now <img src="images/ar_right_icon.png" alt="" /></Link></div>
						  </div>
						</div>
					  </div>


					  <div className="paginationss">
						<ul>
						 <li><img src="images/pagi_left.png" alt="" /></li>	
						 <li><Link to="" className="act">1</Link></li>
						 <li><Link to="">2</Link></li>
						 <li><Link to="">3</Link></li>	
						 <li><Link to="">4</Link></li>
						 <li><Link to="">5</Link></li>
						 <li><img src="images/pagi_right.png" alt="" /></li>	
						</ul>
					  </div>

					</div>
				</TabPanel>
				<TabPanel>
				<div className="main_tabs_data">
                      <div className="un_data_list">
						<div className="usr_mgss"><img src="images/events_sm.png" alt="" /></div>
						<div className="cntent_tx">
						  <div className="dat_time">
							<span className="datess"><img src="images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <h2>Lorem ipsum dolor : sit amet  Sed consequatur  necessitatibus</h2>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr"><Link to="" className="red_mr">Register Now <img src="images/ar_right_icon.png" alt="" /></Link></div>
						  </div>
						</div>
					  </div>


					  <div className="un_data_list">
						<div className="usr_mgss"><img src="images/who_abt.png" alt="" /></div>
						<div className="cntent_tx">
						  <div className="dat_time">
							<span className="datess"><img src="images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <h2>Lorem ipsum dolor : sit amet  Sed consequatur  necessitatibus</h2>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr"><Link to="" className="red_mr">Register Now <img src="images/ar_right_icon.png" alt="" /></Link></div>
						  </div>
						</div>
					  </div>


					  <div className="paginationss">
						<ul>
						 <li><img src="images/pagi_left.png" alt="" /></li>	
						 <li><Link to="" className="act">1</Link></li>
						 <li><Link to="">2</Link></li>
						 <li><Link to="">3</Link></li>	
						 <li><Link to="">4</Link></li>
						 <li><Link to="">5</Link></li>
						 <li><img src="images/pagi_right.png" alt="" /></li>	
						</ul>
					  </div>

					</div>
				</TabPanel>
			</div>
		  </Tabs>
		  </div>

		
		</div>
	</section>



	</>
    )
};

export default Login;