import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from 'react-image-upload'

const Careers = () => {
    const [startDate, setStartDate] = useState(new Date());

    function getImageFileObject(imageFile) {
        console.log({ imageFile })
      }
    
      function runAfterImageDelete(file) {
        console.log({ file })
      }

    return (
        <>
<div className="all_suport_bg">
        <section className="ser_v_araeea evnetsts">
            <div className="container">
                <div className="form_boxx profilss">
                  <h4>Profile</h4>
                  <div className="form_arar mt-0">  
                  <div className="mang_und_listst mt-0">  
                    <form action="">
                        <div className="row">
                          <div className="col-lg-9">
                          <div className="row">                            
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>First Name</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter first name" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter last name" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter email address" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Mobile No.</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter phone number" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Gender</label>
                                    <select className="form-control">
                                        <option value="">Choose your gender</option>
                                    </select>
                                </div>
                            </div>


                            <div className="col-lg-6">
                                <div class="form-group relatives">
                                    <label>Date of Birth</label>   
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}  />
                                    <img src="images/calenders.png" alt="" />                                 
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Referral Code</label>                                    
                                    <input type="text" class="form-control" name="" placeholder="Enter referral code" />
                                </div>
                            </div>

                            

                        </div>                            
                          </div>
                          <div className="col-lg-3">
                             <div id="imgss_upload">
                             <ImageUploader
                                    onFileAdded={(img) => getImageFileObject(img)}
                                    onFileRemoved={(img) => runAfterImageDelete(img)}
                                    />
                             </div>
                          </div>

                          <div className="col-lg-12">
                              <div class="form-group mt-3">
                                <button type="submit" value="Update Profile" class="sub_mitess">Update Profile</button>
                              </div>
                            </div>
                        </div>

                        
                    </form>
                  </div> 
                  </div> 
                </div>
            </div>
        </section>
        </div>
    </>
    )
};

export default Careers;