import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
<footer id='Footer_arae'>
  <div className="th_parts_bxx">
   <div className="container">
   <div class="al_sec_ctxt text-center wit_tx"><h2>Your reliable, efficient, and impactful way to give</h2></div>
    <div className="row">
        <div className="col-lg-4">
           <div className="cont_ftr_box">
            <img src="images/wallpaper.png" className="wlper" alt="" />
            <h5>Efficient</h5>
            <p>Donate swiftly and <span>effortlessly</span></p> 
           </div> 
        </div>

        <div className="col-lg-4">
           <div className="cont_ftr_box">
           <img src="images/wallpaper.png" className="wlper" alt="" />
            <h5>Impactful</h5>
            <p>Direct your support to the <span>people and causes that matter</span> most to you</p> 
           </div> 
        </div>

        <div className="col-lg-4">
           <div className="cont_ftr_box">
           <img src="images/wallpaper.png" className="wlper" alt="" />
            <h5>Reliable</h5>
            <p>Your donation is safeguarded by <span>the Worldtrust community</span></p> 
           </div> 
        </div>
    </div>
   </div>
  </div>  
  
  <div className="ftr_manages">  
    <div className='container'>
        <div className='row brd_managess'>
            <div className='col-lg-6'>
                <div className='subss_cr'>
                    <h4>Sign up for our newsletter</h4>
                    <div className='stay_up_w'>
                        <form action=''>
                            <input type='text' name='email' className='form-control' id='subs_email' value='' placeholder='Your email address' required='' />
                            <button type='submit' value='Submit' className='sub_mitess'><img src='images/ar_w-right.png' alt='' /></button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='social_link_als'>
                    <div className='un_socials_lnks'>
                        <ul>
                            <li>
                                <Link to='/'><img src='images/social_ic1.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='images/social_ic2.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='images/social_ic3.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='images/social_ic4.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='images/social_ic5.png' alt='' /></Link>
                            </li>
                            <li>
                                <Link to='/'><img src='images/social_ic6.png' alt='' /></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col-lg-3'>
                <div className='ftr_partss adss_flss logoss'>
                    <div className='ftr_logoss'><img src='images/ftr_logo.png' alt='' /></div>
                </div>
            </div>

            <div className='col-lg-3 mb_view_hide'>
                <div className='ftr_partss'>
                    <h4>Worldtrust</h4>
                    <ul>
                        <li><Link to='/'>Worldtrust Category</Link></li>
                        <li><Link to='/'>Team Worldtrust</Link></li>
                        <li><Link to='/'>Worldtrust News </Link></li>
                        <li><Link to='/'>Sign Up as a charity</Link></li>
                    </ul>
                </div>
            </div>

            <div className='col-lg-3 mb_view_hide'>
                <div className='ftr_partss'>
                    <h4>About</h4>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/about-us'>About Us</Link></li>
                        <li><Link to='/donate'>Donate</Link></li>
                        <li><Link to='/'>Resources</Link></li>
                        <li><Link to='/contact'>Contact Us</Link></li>
                    </ul>
                </div>
            </div>

            <div className='col-lg-3 mb_view_hide'>
                <div className='ftr_partss'>
                    <h4>Useful Links</h4>
                    <ul>
                        <li><Link to='/events'>Events</Link></li>
                        <li><Link to='/faqs'>FAQ</Link></li>
                        <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                        <li><Link to='/terms-and-conditions'>Terms & Condition</Link></li>
                        <li><Link to='/careers'>Careers</Link></li>
                    </ul>
                </div>
            </div>
        </div>

        <div className='eml_socilal'>
            <div className='em_ico'>Copyright © 2018-2024 www.worldtrust.io All Rights Reserved.</div>
            <div className='social'>
                <div className='socialss'>Designed & Developed by <Link to='/https://www.pearsystem.com/' target='_blank'>PearSystem</Link></div>
            </div>
        </div>
    </div>
    </div>
</footer>

		

        </>
    )
};

export default Footer;