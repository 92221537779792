import { Link } from "react-router-dom";
import {React, useState} from 'react';
import ProgressBar from "@ramonak/react-progress-bar";


const Donate = () => {
    return (
        <>
	<section className="ser_v_araeea green_bg datates_pgs" id="about_pgs">
		<img src="images/flo_partss.png" className="flo_partss" alt="" />
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<div className="al_sprts_pages">
						<h3>Donate</h3>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="cnt_datas">
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum 
					</div>
				</div>
			</div>
		</div>

       <div className="box_ara_partss">
        <div className="container">
			<div className="row">
                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic1.png" alt="" />
                        <p>Education</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic2.png" alt="" />
                        <p>Memorial</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic3.png" alt="" />
                        <p>Emergency</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic4.png" alt="" />
                        <p>Nonprofit</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/hospital.png" alt="" />
                        <p>Medical</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/mailchimp.png" alt="" />
                        <p>Animal</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic5.png" alt="" />
                        <p>Environment</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic6.png" alt="" />
                        <p>Business</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic7.png" alt="" />
                        <p>Community</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic8.png" alt="" />
                        <p>Competition</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/pallet.png" alt="" />
                        <p>Creative</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/calendar.png" alt="" />
                        <p>Event</p>
                    </div>
                </div>


                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic9.png" alt="" />
                        <p>Faith</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic10.png" alt="" />
                        <p>Family</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic11.png" alt="" />
                        <p>Sports</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/donate_ic12.png" alt="" />
                        <p>Travel</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/hand.png" alt="" />
                        <p>Volunteer</p>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="usr_lgo_parts">
                        <img src="images/star-half.png" alt="" />
                        <p>Wishes</p>
                    </div>
                </div>
            </div>
        </div>   
       </div>  
	</section>


    <section className="ser_v_araeea" id="charity_campaign">
     <div className="container">
        <div className="al_sec_ctxt">
            <h2>Medical Cause  <span className="user_lists"><Link to='/'>See more</Link></span></h2>
        </div>

        <div className="row">
            <div className="col-lg-4">
                <Link to="/donate-details">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                       <div className="skills">
                            <ProgressBar completed={88} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
                </Link>
            </div>

            <div className="col-lg-4">
              <Link to="/donate-details">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg1.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={70} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
                </Link>
            </div>

            <div className="col-lg-4">
            <Link to="/donate-details">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg2.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={90} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
                </Link>
            </div>
        </div>
      </div>
    </section>



    <section className="ser_v_araeea pt-0" id="charity_campaign">
     <div className="container">
        <div className="al_sec_ctxt">
            <h2>Emergency Donation  <span className="user_lists"><Link to="/donate-details">See more</Link></span></h2>
        </div>

        <div className="row">
            <div className="col-lg-4">
               <Link to="/donate-details">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                       <div className="skills">
                            <ProgressBar completed={48} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
                </Link>
            </div>

            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg1.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={60} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg2.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={95} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>


    <section className="ser_v_araeea pt-0" id="charity_campaign">
     <div className="container">
        <div className="al_sec_ctxt">
            <h2>Education Fundraising  <span className="user_lists"><Link to='/'>See more</Link></span></h2>
        </div>

        <div className="row">
            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                       <div className="skills">
                            <ProgressBar completed={20} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg1.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={50} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="cm_box_listst">
                    <div className="cm_mg_box">
                        <img src="images/cm_list_bg2.png" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                        <span className="sm_txt">S.P.Foundation <img src="images/gr_tik_sm.png" alt="" /></span>
                        <h3>Flood Rescue Fund : Siem Reap</h3>
                        <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet.</p>
                        
						<div className="skills">
                            <ProgressBar completed={98} bgColor={'#097C81'} height={'12px'} labelSize={'12px'} transitionDuration={'0.3s'} />
                        </div>
                        <h6>Raised 1000000 USD <span className="sm_t">of 6000000 USD</span></h6>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>

    <section className="ser_v_araeea pt-0">
     <div className="container">
       <div className="vei_als">
       <Link to="/donate-details">
          <span className="managess_bnt">View More Categories <img src="images/chevron-right.png" alt="" /></span>
         </Link>
        </div>
      </div> 
    </section>   

        </>
    )
};

export default Donate;