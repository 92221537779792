import { Link } from "react-router-dom";

const Careers = () => {
    return (
        <>

    <section className="ser_v_araeea green_bg manages_heis" id="about_pgs">
		<img src="images/flo_partss.png" className="flo_partss" alt="" />
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<div className="al_sprts_pages">
						<h3>Careers</h3>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="cnt_datas">
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum 
					</div>
				</div>
			</div>
		</div>
	</section>

    <section className="ser_v_araeea" id="careers_pgs">
		<div className="container">
         <div className="rech_araea">
            <form action="">
                <div className="rch_inputss borddr">
                    <img src="images/search.png" alt="" />
                    <input type="text" class="form-control" name="" placeholder="Job Title" />
                </div>

                <div className="rch_inputss borddr">
                    <img src="images/location_b_sm.png" alt="" />
                    <select className="form-control" >
                        <option value="">Location</option>
                    </select>
                    <img src="images/arrow_down_g.png" className="slt_arrow" alt="" />
                </div>

                <div className="rch_inputss">
                    <img src="images/suitcase-alt.png" alt="" />
                    <select className="form-control">
                        <option value="">Job Type</option>
                    </select>
                    <img src="images/arrow_down_g.png" className="slt_arrow" alt="" />
                </div>

                <div className="rch_inputss"><button type="submit" value="Search" class="sub_mitess">Search</button></div>
                
            </form>
         </div>


         <div className="flt_lilsts_araea">
            <div className="und_lists">
                <div className="ic_part"><img src="images/suitcase-alt.png" alt="" /></div>
                <div className="contents">
                    <h3>Operations Manager ( Permanent )</h3>
                    <span>Phnom Penh , Combodia</span>
                    <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                        Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum soc</p>
                </div>
                <div class="red_morss"><Link to="/careers-message">Apply Now</Link></div>
            </div>


            <div className="und_lists">
                <div className="ic_part"><img src="images/suitcase-alt.png" alt="" /></div>
                <div className="contents">
                    <h3>Operations Manager ( Permanent )</h3>
                    <span>Phnom Penh , Combodia</span>
                    <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                        Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum soc</p>
                </div>
                <div class="red_morss"><Link to="/careers-message">Apply Now</Link></div>
            </div>


            <div className="und_lists">
                <div className="ic_part"><img src="images/suitcase-alt.png" alt="" /></div>
                <div className="contents">
                    <h3>Operations Manager ( Permanent )</h3>
                    <span>Phnom Penh , Combodia</span>
                    <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                        Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum soc</p>
                </div>
                <div class="red_morss"><Link to="/careers-message">Apply Now</Link></div>
            </div>

            <div className="und_lists">
                <div className="ic_part"><img src="images/suitcase-alt.png" alt="" /></div>
                <div className="contents">
                    <h3>Operations Manager ( Permanent )</h3>
                    <span>Phnom Penh , Combodia</span>
                    <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                        Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum soc</p>
                </div>
                <div class="red_morss"><Link to="/careers-message">Apply Now</Link></div>
            </div>

            <div className="und_lists">
                <div className="ic_part"><img src="images/suitcase-alt.png" alt="" /></div>
                <div className="contents">
                    <h3>Operations Manager ( Permanent )</h3>
                    <span>Phnom Penh , Combodia</span>
                    <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                        Donec quam felis,Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,Cum soc</p>
                </div>
                <div class="red_morss"><Link to="/careers-message">Apply Now</Link></div>
            </div>

            <div className="paginationss">
						<ul>
						 <li><img src="images/pagi_left.png" alt="" /></li>	
						 <li><Link to="" className="act">1</Link></li>
						 <li><Link to="">2</Link></li>
						 <li><Link to="">3</Link></li>	
						 <li><Link to="">4</Link></li>
						 <li><Link to="">5</Link></li>
						 <li><img src="images/pagi_right.png" alt="" /></li>	
						</ul>
					  </div>
         </div>

        </div>
    </section>    


    <section className="ser_v_araeea pt-2">
        <div className="container">
            <div className="faq_bgrr">
                <img src="images/faq_bgrr.png" className="bg_faqq" alt="" />
                <div className="over_cntss">
                   <div className="medialss"> 
                    <h2>Still Need Help?</h2>
                    <p>We’re here to support</p>
                    <span>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum </span>
                    <div className="link_cntnts"><Link to="/contact">Contact Us <img src="images/ar_right_icon.png" alt=""></img></Link></div>
                    </div> 
                </div>
            </div>
        </div>
    </section>

    </>
    )
};

export default Careers;