import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { useState, useEffect } from "react";
import ProgressBar from "@ramonak/react-progress-bar";




const Header = () => {
	   // Sticky Menu Area
        useEffect(() => {
            window.addEventListener('scroll', isSticky);
            return () => {
                window.removeEventListener('scroll', isSticky);
            };
        });
    
               
        /* Method that will fix header after a specific scrollable */
               const isSticky = (e) => {
                    const header = document.querySelector('.header-section');
                    const scrollTop = window.scrollY;
                    scrollTop >= 82 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
                };
	
    return (
        <>
          <header className='header-section' id='fixedds'>
    <div className='container'>
            <nav>
			<div className='line'>
                <div className='lgo_mg'>
                    <Link to='/'><img src='images/w_logo.png' alt='' className='lgo_parts' /></Link>
                </div>
                <div className='top-nav s-12 l-12'>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/about-us'>About Us</Link></li>
                        <li><Link to='/donate'>Donate </Link></li>
                        <li><Link to='/events'>Events</Link></li>
                        <li><Link to='/contact'>Contact Us</Link></li>
                        <li className='mb_view_show'><Link to='/login'>Login</Link></li>
                        <li className='mb_view_show'><Link to='/'>Sign up</Link></li>
                    </ul>

                    <div className='bk_now_flds mb_view_hide'>
                        <ul>
                            <li><Link to='/'>Login</Link> <span className='spss_lst'>/</span> <Link to='/'>Sign up</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
			
			

            </nav>

			</div>
			</header>
			
			
			

			
        </>
    )
};

export default Header;