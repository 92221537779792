const Login = () => {
    return (
        <>
	
	<section className="ser_v_araeea green_bg" id="about_pgs">
		<img src="images/flo_partss.png" className="flo_partss" alt="" />
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<div className="al_sprts_pages">
						<p>About Us</p>
						<h3>A path to purposeful impact on society</h3>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="cnt_datas">
						WorldTrust.io is a pioneering platform designed to unify charitable efforts through an innovative blend of transparency and collaboration. It provides an intuitive space where NGOs and donors converge, offering a
						streamlined experience that eliminates inefficiencies and fosters trust among its users. Utilizing blockchain technology, WorldTrust.io ensures that every donation is traceable and every charitable cause is verified,
						empowering users to contribute to change with confidence. Serving as a catalyst for global cooperation, the platform supports diverse causes, from educational initiatives in underserved areas to environmental
						conservation projects around the globe. By aggregating these efforts, WorldTrust.io amplifies each contributor's impact and facilitates sustained change across communities.
					</div>
				</div>
			</div>
		</div>
	</section>

	<section className="ser_v_araeea abt_mgeess_bx">
		<div className="container">
			<div className="row">
				<div className="col-lg-4">
					<div className="mg_ab_area">
						<img src="images/about_us_sm1.png" alt="" />
					</div>
				</div>
				<div className="col-lg-4">
					<div className="mg_ab_area">
						<img src="images/about_us_sm2.png" alt="" />
					</div>
				</div>
				<div className="col-lg-4">
					<div className="mg_ab_area">
						<img src="images/about_us_sm3.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</section>

	<section className="ser_v_araeea pt-0 abt_cont_hm">
		<div className="container">
			<div className="mis_contents">
				<div className="content_txs">
					<h4>Our Mission</h4>
					<p>
						"WorldTrust.io is dedicated to bridging the gap between passion and impact by unifying NGOs and donors on a transparent, secure, and innovative platform. Our mission unfolds in phases: Phase One: To uplift third-world
						countries by providing access to essential resources, education, and infrastructure. We aim to empower local communities by supporting impactful projects that promote self-sufficiency and sustainable development. Phase
						Two: To foster economic growth by facilitating partnerships between NGOs, businesses, and government organizations, ensuring that resources are allocated effectively and responsibly for long-term viability. Phase Three:
						To create a global network that shares successful strategies and innovations, enabling countries to learn from one another in addressing their unique challenges and aspirations for development. Through this phased
						approach, we aim to transform societies, improve living conditions, and promote opportunities for future generations in every corner of the globe."
					</p>
				</div>
				<div className="content_txs">
					<h4>Our Vision</h4>
					<p>"To create a world where every charitable act is empowered through unity, transparency, and trust, transforming lives and communities globally."</p>
				</div>
			</div>
		</div>
	</section>

	<section className="ser_v_araeea greas_bg" id="how_we_w_hm">
		<div className="container">
			<div class="al_sec_ctxt"><h2>How we work</h2></div>
			<div className="row">
				<div className="col-lg-7">
					<div className="usr_ctnxt">
						<p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta.</p>
						<div className="usr_cotet_list">
							<span className="count_numbbr">01</span>
							<h5>Lorem ipsum dolor sit amet. Sed consequatur necessita</h5>
							<p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus min</p>
						</div>

						<div className="usr_cotet_list">
							<span className="count_numbbr">02</span>
							<h5>Lorem ipsum dolor sit amet. Sed consequatur necessita Lorem ipsum</h5>
						</div>

						<div className="usr_cotet_list">
							<span className="count_numbbr">03</span>
							<h5>Lorem ipsum dolor sit amet. Sed consequatur necessita Lorem ipsum</h5>
						</div>
					</div>
				</div>
				<div className="col-lg-5">
					<div className="how_usr_imgs">
						<img src="images/who_abt.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</section>

	<section className="ser_v_araeea" id="we_teams_hm">
		<div className="container">
			<div class="al_sec_ctxt text-center"><h2>We’ve got an Exceptional team</h2></div>
			<div className="row justy_centr">
				<div className="col-lg-3">
					<div className="usr_tm_prof">
						<div className="us_imgss"><img src="images/team_m1.png" alt="" /></div>
						<h6>Sum Darareaksmey</h6>
						<p>Founder & CEO</p>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="usr_tm_prof">
						<div className="us_imgss"><img src="images/team_m2.png" alt="" /></div>
						<h6>Saechao Champei</h6>
						<p>Technology</p>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="usr_tm_prof">
						<div className="us_imgss"><img src="images/team_m3.png" alt="" /></div>
						<h6>Saelee Mealea</h6>
						<p>Design</p>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="usr_tm_prof">
						<div className="us_imgss"><img src="images/team_m4.png" alt="" /></div>
						<h6>Long Jorani</h6>
						<p>Finance</p>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="usr_tm_prof">
						<div className="us_imgss"><img src="images/team_m5.png" alt="" /></div>
						<h6>Meng Bourey</h6>
						<p>Support</p>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="usr_tm_prof">
						<div className="us_imgss"><img src="images/team_m6.png" alt="" /></div>
						<h6>Saephan Bourey</h6>
						<p>Marketing</p>
					</div>
				</div>
			</div>
		</div>
	</section>

	</>
    )
};

export default Login;