import './App.css';
import './responsee.css';
import './bootstrap.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./pages/common/Header";
import Footer from './pages/common/Footer';
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import BlogsDetails from "./pages/BlogsDetails";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import AboutUs from './pages/AboutUs';
import PayByBank from './pages/PayByBank';
import PayByCard from './pages/PayByCard';
import DonationSuccessful from './pages/DonationSuccessful';
import Login from './pages/Login';
import Donate from './pages/Donation/Donate';
import DonateDetails from './pages/Donation/DonateDetails';
import Events from './pages/Events/Events';
import EventsDetails from './pages/Events/EventsDetails';
import FAQs from './pages/FAQs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndCondtions';
import Careers from './pages/Careers';
import CareersMessage from './pages/CareersMessage';
import Profile from './pages/Profile';


function App() {
	return (
		<>
			<BrowserRouter>
				<Header />
				<Routes>
					<Route index element={<Home />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/donate" element={<Donate />} />
					<Route path="/donate-details" element={<DonateDetails />} />
					<Route path="/pay-by-bank" element={<PayByBank />} />
					<Route path="/pay-by-card" element={<PayByCard />} />
					<Route path="/donation-successful" element={<DonationSuccessful />} />															
					<Route path="/login" element={<Login />} />
					<Route path="/donate" element={<Donate />} />
					<Route path="/events" element={<Events />} />
					<Route path="/events-details" element={<EventsDetails />} />					
					<Route path="/faqs" element={<FAQs />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
					<Route path="/careers" element={<Careers />} />
					<Route path="/careers-message" element={<CareersMessage />} />
					<Route path="/profile" element={<Profile />} />										
					<Route path="/blogs" element={<Blogs />} />
					<Route path="/blogs-details" element={<BlogsDetails />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="*" element={<NoPage />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</>
	);
}

export default App;
